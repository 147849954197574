.evaluation_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.evaluation_button {
    margin-top: 0 !important;
}

.star {
    font-size: clamp(15px, 3.5vw, 50px);
}

.evaluation_thanks_text{
    font-size: var(--keyValueFontSize);
}
