.info_pair {
    font-size: var(--keyValueFontSize);
    margin-bottom: var(--keyValueMargin);
}

.info_key {
    color: var(--lightGrey);
    word-break: break-word;
    line-height: 100%;
}
.info_value {
    color: var(--darktGrey);
    word-break: break-word;
}

.info_value_link {
    padding: 0.5% 2% !important;
    background-color: var(--gold) !important;
    border: none !important;
    margin-top: 1%;
    font-size: var(--buttonFontSize) !important;
    &:hover {
        background-color: var(--gold) !important;
    }
    &:active {
        background-color: var(--gold) !important;
    }
    &:focus {
        background-color: var(--gold) !important;
    }
}

.info_value_link_icon {
    padding-right: 5px !important;
}
