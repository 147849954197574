@font-face {
    font-family: "Lato-Regular";
    src: url(./fonts/Lato-Regular.eot);
    src:
        url(./fonts/Lato-Regular.eot?#iefix) format("embedded-opentype"),
        url(./fonts/Lato-Regular.otf) format("otf"),
        url(./fonts/Lato-Regular.woff) format("woff"),
        url(./fonts/Lato-Regular.woff2) format("woff2");
}

@font-face {
    font-family: "PlayfairDisplay-Medium";
    src: url(./fonts/PlayfairDisplay-Medium.eot);
    src:
        url(./fonts/PlayfairDisplay-Medium.eot?#iefix) format("embedded-opentype"),
        url(./fonts/PlayfairDisplay-Medium.otf) format("otf"),
        url(./fonts/PlayfairDisplay-Medium.woff) format("woff"),
        url(./fonts/PlayfairDisplay-Medium.woff2) format("woff2");
}

body > * {
    font-family: "Lato-Regular" !important;
}

:root {
    --titleFontFamily: "PlayfairDisplay-Medium";

    --darkGrey: #2f2424;
    --lightGrey: #999999;
    --backgroundGray: #333333;
    --gold: #ac8b51;
    --downloadIconColor: rgb(47, 36, 36, 1);

    --iconWrapperMargin: 5%;
    --shadowMargin: 5%;
    --titleMargin: 5%;

    --productTitleFontSize: clamp(23px, 2.4vw, 32px);
    --keyValueFontSize: calc(11px + 0.2vw);
    --buttonFontSize: calc(9px + 0.2vw);
    --keyValueMargin: 1.5%;
    --iconMargin: 1%;

    --fileIconHeight: clamp(20px, 10vw, 50px);
    --certificateIconWidth: clamp(100px, 20vw, 250px);

    --sectionTtitleFontSize: clamp(20px, 2vw, 28px);

    --textFontFamily: "Orbitron-Medium";
    --mainSectionGap: 5%;
    --logoImageGap: 3%;

    --maxWidthofContent: 1130px;

    --layoutMargin: 1vw 5vw 0 5vw;
}

@media screen and (max-width: 768px) {
    :root {
        --layoutMargin: 2vw 2vw 0 2vw;
    }
}
