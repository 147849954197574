.certificate_info_wrapper {
    font-size: var(--keyValueFontSize);
}

.certificate_info_wrapper > * {
    word-break: break;
    line-height: 150%;
}

.certificate_title {
    color: var(--lightGrey);
}

.certificate_icon {
    width: var(--certificateIconWidth);
    max-width: 100%;
}
