.slider_wrapper {
    margin-top: clamp(10px, 3%, 50px);
}

.gallery_image {
    height: 25vmin;
    width: auto;
    max-width: 100%;
    object-fit: cover;
    border-radius: 5%;
}

.slider_item {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around !important;
    align-items: center;
}
