//**************** only for safari **************************
.ios_main_wrapper_1 {
    height: 100vh;
    min-height: 100vh;
    background-image: url("../../assets/fass_Background.png");
    background-position: center;
    background-size: cover;
    overflow: auto;
}
//**************** only for safari **************************

.main_wrapper_2 {
    background-color: rgb(255, 255, 255, 0.65);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main_wrapper_3 {
    max-width: var(--maxWidthofContent);
    margin: var(--layoutMargin);
}

body::before {
    content: " ";
    display: block;
    background-image: url("../../assets/fass_Background.png");
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: -10; // This is pretty important.
}
