.document_item_wrapper {
    display: flex;
    flex-direction: row;
    font-size: var(--keyValueFontSize);
    margin-bottom: var(--keyValueMargin);
    align-items: center;
}

.document_title_part {
    width: 97%;
    display: flex;
    align-items: center;
}

.file_icon {
    color: var(--downloadIconColor);
    height: var(--fileIconHeight);
}

.doc_link {
    color: black;
    text-decoration: none;
    margin-left: 2%;
    letter-spacing: 0.1em;
    transition: all 0.3s ease-in-out;
    word-break: break-all;
}

.download_icon {
    color: var(--downloadIconColor) !important;
    margin-left: var(--iconMargin);
    cursor: pointer;
}
