.layout_wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--mainSectionGap);
}

.layout_title {
    flex: 1;
}

.layout_content {
    flex: 2;
    padding-left: 10%;
}
