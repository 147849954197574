.icon_wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: var(--iconWrapperMargin);
    height: 95vh;
}

.icon_1 {
    max-height: 90%;
    max-width: 100%;
    object-fit: contain;
}

.icon_2 {
    max-width: clamp(10px, calc(25vw), 270px);
}

.icon_1:first-child {
    margin-bottom: var(--shadowMargin);
}

// ***********details***********

.general_detail_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.general_title_wraper {
    flex: 1;
    display: flex;
    flex-direction: row;
    font-size: var(--productTitleFontSize);
}

.language_title_part {
    flex: 8;
    margin-bottom: var(--titleMargin);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.language_wrapper {
    text-align: right;
}

.product_title {
    word-break: break;
    font-family: var(--titleFontFamily);
}

.manufacturer_icon_wrapper {
    flex: 1;
    align-self: start;
    min-width: 150px;
    margin: var(--iconMargin);
}

.manufacturer_icon {
    max-width: 100%;
    object-fit: contain;
}

.general_info_wrapper {
    flex: 2;
    margin-top: var(--titleMargin);
}

@media screen and (max-width: 768px) {
    .manufacturer_icon_wrapper {
        text-align: center;
        margin: 0;
        position: relative;
    }

    .manufacturer_icon {
        max-width: 200px;
        width: clamp(100px, 25vw, 200px);
    }

    .language_wrapper {
        position: absolute;
        top: 0;
        right: 0;
    }

    .product_title {
        text-align: center;
        margin: 5% 0;
    }

    .icon_wrapper {
        margin: 5% 0;
        height: unset;
    }

    .icon_1 {
        height: 70vh;
    }

    .icon_2 {
        max-width: clamp(120px, calc(20vw), 200px);
    }
}
