.footer_wrapper_1 {
    background-color: var(--backgroundGray);
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--keyValueFontSize);
    padding: clamp(10px, 3%, 50px) 0;
}

.footer_wrapper_2 {
    margin: var(--layoutMargin);
    max-width: var(--maxWidthofContent);
}

.footer_info_part {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 3%;
}

.footer_info_part>* {
    flex: 1;
}

.footer_title_logo {
    max-width: 80%;
}

.footer_section_title {
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
    margin-bottom: 15%;
    line-height: 150%;
}

.section,
.sub_section {
    line-height: 150%;
}

.section>*,
.sub_section>* {
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.sub_section_wrapper {
    margin-bottom: 5%;
}

.legal_logo {
    width: 100px;
    margin: 1%;
    margin-left: 0;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;

    &:hover {
        transform: scale(1.5);
    }
}

.logo_part_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1%;
}

.footer_logo {
    max-width: 100%;
    width: 100px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;

    &:hover {
        transform: scale(1.25);
    }
}

.up_icon_wrapper {
    float: right;
    background-color: rgb(81, 81, 81);
    padding: 20px;
    border-radius: 50% 0 50% 0;
    cursor: pointer;
}

.up_icon {
    color: var(--gold) !important;
    font-size: 25px !important;
}

@media screen and (max-width: 768px) {
    .footer_wrapper_2 {
        text-align: center;
    }

    .footer_info_part {
        display: block;
        text-align: center;
    }

    .footer_title_logo {
        max-width: 35%;
        padding: 10% 0 5% 0;
    }

    .footer_section_title {
        margin-bottom: 5%;
        margin-top: 5%;
    }

    .legal_logo {
        width: 40%;

        &:hover {
            transform: none;
        }
    }

    .footer_logo {
        &:hover {
            transform: none;
        }
    }

    .up_icon_wrapper {
        float: none;
        margin: 5% 0;
        display: inline-block;
        border-radius: 5px;
    }
}

@media screen and (max-width: 768px) {
    .footer_logo {
        width: 75px;
    }
}